<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">选择销售合同</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="next">下一步</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div style="margin-top: 20px">
      <el-table
        :data="productList"
        row-key="id"
        ref="table"
        max-height="750"
        class="egrid erp-table__list erp-view__table table-loading"
        @selection-change="handleSelectionChange"
        border
      >
        <el-table-column
          :selectable="selectableFn"
          type="selection"
          reserve-selection
          align="center"
          width="56"
        ></el-table-column>
        <el-table-column
          label="销售合同号"
          prop="businessContractNo"
          class-name="no-pd"
          align="center"
          width="146"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            {{ filterName(scope.row.businessContractNo) }}
          </template>
        </el-table-column>
        <el-table-column
          label="供应商合同号"
          prop="supplierContractNo"
          class-name="no-pd"
          align="center"
          width="100"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            {{ filterName(scope.row.supplierContractNo) }}
          </template>
        </el-table-column>
        <el-table-column
          label="资金方"
          prop="fundingShortName"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="90"
        >
          <template v-slot="scope">
            {{ filterName(scope.row.fundingShortName) }}
          </template>
        </el-table-column>
        <el-table-column
          label="抬头"
          prop="consigneeShortName"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="90"
        >
          <template v-slot="scope">
            {{ filterName(scope.row.consigneeShortName) }}
          </template>
        </el-table-column>
        <el-table-column
          label="客户"
          prop="customerName"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="120"
        >
          <template v-slot="scope">
            {{ filterName(scope.row.customerName) }}
          </template>
        </el-table-column>
        <el-table-column
          label="订单状态"
          prop="statusDisplay"
          align="center"
          class-name="no-pd"
          show-overflow-tooltip
          width="80"
        >
          <template v-slot="scope">
            {{ filterName(scope.row.statusDisplay) }}
          </template>
        </el-table-column>
        <el-table-column
          label="上游业务合同号"
          prop="contractNo"
          class-name="no-pd"
          align="center"
          width="120"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            {{ filterName(scope.row.contractNo) }}
          </template>
        </el-table-column>
        <el-table-column
          label="签约日期"
          prop="signDate"
          class-name="no-pd"
          align="center"
          width="100"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            {{ filterName(scope.row.signDate) }}
          </template>
        </el-table-column>
        <el-table-column
          label="合同文件"
          prop="fileVoList"
          class-name="no-pd"
          align="center"
          width="80"
          show-overflow-tooltip
        >
          <template v-slot="scope">
            <tableFileContent
              :readOnly="true"
              :fileUrlsArray="scope.row.fileVoList"
              key-name="name"
              key-url="url"
              key-id="attachmentId"
            ></tableFileContent>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-total">已选{{ selectionList.length }}份合同</div>
  </div>
</template>
<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import { httpPost } from '@/api';
export default {
  components: { tableFileContent },
  props: {
    businessContractNos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectionList: [],
      productList: [],
      total: 0,
    };
  },

  created() {
    this.getDatas();
  },
  methods: {
    selectableFn(row) {
      return !row.contractNo;
    },
    // 下一步
    next() {
      if (this.selectionList.length > 0) {
        this.submitForm();
      } else {
        this.errorTip('请选择销售合同');
      }
    },
    submitForm() {
      const orderIdArray = this.selectionList.map((item) => {
        return item.orderId;
      });
      this.$emit('success', orderIdArray);
    },
    // 返回
    back() {
      this.$emit('cancel', false);
    },
    handleSelectionChange(val) {
      this.selectionList = val;
    },
    async getDatas() {
      const businessContractNos = this.businessContractNos;
      const res = await httpPost(
        '/malicrm/order/queryContractOrderByContractNo',
        { businessContractNos }
      );
      if (res.code === 200) {
        this.productList = [];
        this.selectionList = [];
        setTimeout(() => {
          this.productList = res.result;
          this.$refs.table.clearSelection();
        }, 100);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product_table_style {
  margin-top: 24px;
}
.bottom-total {
  margin-left: 20px;
  margin-top: 10px;
}
</style>
