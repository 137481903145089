import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      type: 'input',
      value: '',
      minWidth: 140,
      component: defineComponent({
        template: `
        <div :class="row.businessContractNo?'record__link':''"
        >{{filterName(row.businessContractNo)}}</div>
        `,
        props: ['row'],
        setup() {
          return {};
        },
      }),
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      type: 'input',
      value: '',
      minWidth: 160,
    },
    {
      label: '资金方',
      prop: 'fundingShortName',
      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',

      type: 'input',
      value: '',
      minWidth: 120,
    },
    {
      label: '客户',
      prop: 'customerName',
      type: 'input',
      value: '',
      minWidth: 150,
    },
    {
      label: '业务类型',
      prop: 'category',
      propDesc: 'categoryDesc',
      type: 'signSelect',
      value: '',
      minWidth: 120,
      options: 'product_category',
    },
    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusDisplay',
      type: 'multiSelect',
      value: [1],
      options: 'order_status',
      minWidth: 115,
    },
    {
      label: '上游业务合同号',
      prop: 'contractNo',
      type: 'input',
      value: '',
      minWidth: 160,
    },
    {
      label: '签约日期',
      prop: 'signDate',
      filterProp: ['signStartDate', 'signEndDate'],
      minWidth: 120,
      type: 'time',
      value: [],
    },
    {
      label: '合同金额(元)',
      prop: 'amountDisplay',
      filterProp: ['amountMinVal', 'amountMaxVal'],
      type: 'number', // 筛选类型 time, // 筛选类型 number
      value: [],
      minWidth: 140,
    },
    {
      label: '生效日期',
      prop: 'startDate',
      filterProp: ['startStartDate', 'startEndDate'],
      type: 'time', // 筛选类型 time
      value: [],
      minWidth: 120,
    },
    {
      label: '到期日期',
      prop: 'endDate',
      minWidth: 150,
      type: 'time',
      filterProp: ['endStartDate', 'endEndDate'],
      value: [],
    },
    {
      label: '操作人',
      prop: 'lastOperator',
      minWidth: 120,
      type: 'input',
      value: '',
    },
    {
      label: '操作时间',
      prop: 'updateTime',
      minWidth: 150,
      type: 'time',
      filterProp: ['updateStartTime', 'updateEndTime'],
      value: [],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 88,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button type="primary" plain @click="add(row)"
        v-if="((hasPermission('erp:order:saveOrderContract') && row.contractNo == '') || (hasPermission('erp:order:saveOrderContract') &&row.contractNo == null))"
        >新增</el-button>
        `,
        props: ['row'],
        setup() {
          const add = (row) => {
            Instance.setupState.add(row);
          };
          return { add };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};

//
