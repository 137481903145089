<template>
  <div v-loading="loading" element-loading-text="正在导入，请勿进行其他操作">
    <div>
      <div class="erp-form__header header-bar__sticky">
        <div class="erp-form__title">
          {{ step == 1 ? '导入合同' : '选择销售合同' }}
        </div>
        <div class="erp-form-bar">
          <el-button size="small" type="primary" class="erp-primary__btn" @click="nextStep" v-if="step === 1">下一步</el-button>
          <el-button size="small" type="primary" class="erp-primary__btn" @click="showUpLoad" v-if="step === 2" style=""> 上传合同文件</el-button>
          <el-button size="small" type="primary" class="erp-primary__btn" @click="submit" v-if="step === 2">确认导入</el-button>
          <div class="erp-default__btn" @click="back">返回</div>
        </div>
      </div>
      <!-- 步骤一 -->
      <div v-if="step === 1">
        <div style="padding: 0 16px">
          <div class="erp-form__title f16 import-top req no-leftborder" style="">选择导入文件</div>
          <div class="upload-box">
            <SingleFileContent
              :uploadUrl="customUrl"
              :isImport="true"
              :titleImg="''"
              :fromType="1"
              :fileType="fileType"
              :queryNum="0"
              :max="1"
              :fileList="fileUrlsArray"
              @getFiles="getFiles"
              ref="importUpload"
              :autoUpload="false"
              tips=" "
            >
            </SingleFileContent>
            <div class="upload-tips">
              允许类型：xls、xlsx&nbsp;&nbsp;&nbsp;文件大小上限：30M&nbsp;&nbsp;&nbsp;只支持上传1个文件&nbsp;&nbsp;&nbsp;<span
                class="record__link"
                @click="downLoadTmp"
                >《合同导入表》<span class="under">模板下载</span>
              </span>
            </div>
          </div>
        </div>
        <!-- 应收项目 -->
        <div v-if="errorShow">
          <div style="margin-top: 30px; margin-bottom: 12px" class="order-title__bar op-16">
            <div class="erp-form__title f16 no-leftborder">错误日志</div>
          </div>
          <div>
            <el-table :data="errorData" border class="egrid erp-table__list erp-view__table">
              <el-table-column label="错误行数" align="center" show-overflow-tooltip width="110" class-name="no-pd">
                <template #default="scope">
                  {{ filterName(scope.row.cellRowDesc) }}
                </template>
              </el-table-column>
              <el-table-column label="错误原因" align="center" show-overflow-tooltip class-name="no-pd">
                <template #default="scope">
                  {{ filterName(scope.row.description) }}
                </template>
              </el-table-column>
            </el-table>
            <div class="mali-erp-list-pagination">
              <pc-pagination
                ref="pcPagination"
                url="/malicrm/excel/queryExcelImportLineLogDetailList"
                :queryData="errorQueryForm"
                @change="queryCallBackFn"
              ></pc-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 步骤2 -->
      <div v-else>
        <el-table
          :data="formData.dtoList"
          border
          class="egrid erp-table__list erp-view__table table-loading"
          id="scrollTables"
          row-key="arapAuditSeq"
          @selection-change="selectionChange"
        >
          <el-table-column type="selection" width="55" align="center"> </el-table-column>
          <el-table-column label="销售合同号" class-name="no-pd req" align="center" width="200">
            <template v-slot="scope">
              {{ filterName(scope.row.businessContractNo) }}
            </template>
          </el-table-column>
          <el-table-column label="供应商合同号" class-name="no-pd req" align="center" width="200">
            <template v-slot="scope">
              {{ filterName(scope.row.supplierContractNo) }}
            </template>
          </el-table-column>
          <el-table-column label="资金方" class-name="no-pd req" align="center" width="120">
            <template v-slot="scope">
              {{ filterName(scope.row.funding) }}
            </template>
          </el-table-column>
          <el-table-column label="抬头" class-name="no-pd req" align="center" width="120">
            <template v-slot="scope">
              {{ filterName(scope.row.shortName) }}
            </template>
          </el-table-column>
          <el-table-column label="客户" class-name="no-pd req" align="center" width="200">
            <template v-slot="scope">
              {{ filterName(scope.row.customer) }}
            </template>
          </el-table-column>
          <el-table-column label="业务类型" class-name="no-pd req" align="center" width="110">
            <template v-slot="scope">
              {{ filterName(scope.row.orderCategoryDesc) }}
            </template>
          </el-table-column>
          <el-table-column label="订单状态" class-name="no-pd" align="center" width="110">
            <template v-slot="scope">
              {{ filterName(scope.row.statusDesc) }}
            </template>
          </el-table-column>
          <el-table-column label="上游业务合同号" class-name="no-pd" align="center" width="110">
            <template v-slot="scope">
              {{ filterName(scope.row.contractNo) }}
            </template>
          </el-table-column>
          <el-table-column label="签约日期" class-name="no-pd" align="center" width="160">
            <template v-slot="scope">
              {{ filterName(scope.row.signDate) }}
            </template>
          </el-table-column>
          <el-table-column label="合同文件" class-name="no-pd" align="center" width="90">
            <template v-slot="scope">
              <template v-if="scope.row.attachmentDtos">
                <tableFileContent :readOnly="true" :fileUrlsArray="scope.row.attachmentDtos" key-name="name" key-url="url" key-id="id"></tableFileContent>
              </template>
              <template v-else>-</template>
            </template>
          </el-table-column>
          <el-table-column label="合同金额" class-name="no-pd" align="center" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.amountDesc) }}
            </template>
          </el-table-column>
          <el-table-column label="生效日期" class-name="no-pd" align="center" show-overflow-tooltip width="160">
            <template v-slot="scope">
              {{ filterName(scope.row.startDate) }}
            </template>
          </el-table-column>
          <el-table-column label="到期日期" class-name="no-pd" align="center" show-overflow-tooltip width="160">
            <template v-slot="scope">
              {{ filterName(scope.row.endDate) }}
            </template>
          </el-table-column>
          <el-table-column label="合同备注" class-name="no-pd" align="center" show-overflow-tooltip>
            <template v-slot="scope">
              {{ filterName(scope.row.remark) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <tableFileContent
      class="margin-upload"
      width="970px"
      v-if="showUpLoadVisable"
      fileType="noZip"
      :maxImg="3"
      tips="允许类型：pdf,doc,docx,jpg,png,gif,txt,xls,xlsx   大小上限：30M   限制上传3个文件"
      :size="30"
      @getFileUrls="getFinalPayFile"
      ref="tableFileUpLoad"
      setKeyId="id"
      setKeyName="name"
      setKeyUrl="url"
      keyName="name"
      keyUrl="url"
      :fileUrlsArray="files"
    >
      <div></div>
    </tableFileContent>
    <div class="record" v-if="step === 2">已选{{ selectionList.length }}条记录</div>
  </div>
</template>
<script>
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import PcPagination from '@/components/Pagination/Pagination.vue';
import { errorTip, showFiles } from '@/utils/publicMethods';
export default {
  components: {
    SingleFileContent,
    tableFileContent,
    PcPagination,
  },
  props: {},
  data() {
    return {
      loading: false,
      errorShow: false,
      isAjaxLoading: false,
      errorData: [], // 错误信息列表
      errorQueryForm: {
        logId: null,
        pageNum: 1,
        pageSize: 10,
      },
      showUpLoadVisable: false, // 展示批量上传文件展示
      files: [],
      step: 1,
      customUrl: process.env.VUE_APP_API_ROOT + '/malicrm/order/importOrderContract',
      fileType: 'excel',
      fileUrlsArray: [],
      selectionList: [], // 选中列表
      formData: {
        dtoList: [],
      },
    };
  },

  computed: {
    // 索引列表集合
    indexList() {
      return this.selectionList.map((v) => v.arapAuditSeq);
    },
  },
  methods: {
    queryCallBackFn(res) {
      this.errorData = res;
      // if (res.code === 200) {
      //   this.errorData = res;
      // }
    },
    selectionChange(row) {
      console.log('row', row);
      // 获取选中的行数据
      this.selectionList = row;
    },
    nextStep() {
      // 校验下一步,触发手动上传
      const len = this.$refs.importUpload.fileCount;
      if (len > 0) {
        this.$refs.importUpload.submitUpload();
        this.loading = true;
      } else {
        this.errorTip('请先上传文件。');
      }
    },
    downLoadTmp() {
      // 下载模板地址
      this.$showFiles('合同导入表.xlsx', 'https://minio.malleegroup.com/template/合同导入表.xlsx', false);
    },
    getFiles(res) {
      console.log(res);
      // 获取导入数据，构建数据列
      this.loading = false;
      if (res.code === 200) {
        if (res.result.hasError === true) {
          this.errorTip('导入失败');
          this.errorQueryForm.logId = res.result.importLogId;
          this.errorShow = false;
          setTimeout(() => {
            this.errorShow = true;
          }, 50);
        } else {
          this.successTip('导入成功');
          this.formData.dtoList = res.result.orderContractVoList;
          this.formData.dtoList.forEach((v, index) => {
            v.arapAuditSeq = index;
          });
          this.step = 2;
        }
      } else {
        this.errorTip(res.message);
      }
      this.$nextTick(() => {
        if (this.$refs.importUpload) {
          this.$refs.importUpload.fileCount = 0; // 必须置空，否则直接上传不会触发;
        }
        this.fileUrlsArray = [];
      });
    },
    getLogData() {
      // 获取错误日志信息
      this.ajax({
        type: 'POST',
        url: '/malicrm/excel/queryExcelImportLineLogDetailList',
        data: {},
        success: (res) => {
          if (res.code === 200) {
            this.errorData = res.result.list;
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    getRowFileUrls({ data, index }) {
      // 单行文件编辑修改
      this.formData.dtoList[index].attachmentDtos = data;
      // 行文件标记
    },
    getFinalPayFile({ data }) {
      console.log(data);
      // 获取上传的文件
      const indexs = this.getIndexs(this.formData.dtoList, this.selectionList, 'arapAuditSeq');
      console.log(indexs);
      indexs.forEach((index) => {
        this.formData.dtoList[index].attachmentDtos = data;
      });
      this.selectionList.forEach((v) => {
        v.attachmentDtos = data;
      });
    },
    showUpLoad() {
      // 校验打开上传文件
      if (this.selectionList.length > 0) {
        this.showUpLoadVisable = false;
        setTimeout(() => {
          this.showUpLoadVisable = true;
        }, 10);
        setTimeout(() => {
          this.$refs.tableFileUpLoad.uploadVisable = true;
        }, 100);
      } else {
        this.errorTip('请选择关联数据');
      }
    },
    validateTable() {
      let valid = true;
      let flag = this.formData.dtoList.every((v) => {
        return v.attachmentDtos;
      });
      if (this.selectionList.length < 1) {
        valid = false;
        errorTip('请选择要导入的数据');
        return valid;
      }
      if (!flag) {
        valid = false;
        errorTip('有未上传合同文件的订单，无法导入');
        return valid;
      }
      return valid;
    },
    submit() {
      if (!this.isAjaxLoading) {
        if (this.validateTable()) {
          this.isAjaxLoading = true;
          this.ajax({
            type: 'POST',
            url: '/malicrm/order/confirmImportOrderContract',
            data: [...this.selectionList],
            success: (res) => {
              this.isAjaxLoading = false;
              if (res.code === 200) {
                this.successTip('提交成功');
                this.$emit('cancel', true);
              } else {
                this.errorTip(res.message);
              }
            },
            error: (res) => {
              console.log(res);
              this.isAjaxLoading = false;
            },
          });
        }
      }
    },
    fileClick(row, index) {
      let { name, url } = row.attachmentDtos[index];
      showFiles(name, url);
    },
    back() {
      // 返回操作
      if (this.step === 2) {
        this.step = 1;
      } else {
        this.$emit('cancel', false);
      }
    },
    getIndexs(targetArrays, currentArrays, name) {
      // 获取对应下标值
      const indexs = [];
      currentArrays.forEach((v) => {
        indexs.push(targetArrays.findIndex((item) => item[name] === v[name]));
      });
      return indexs;
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-tips {
  display: flex;
  align-items: center;
  white-space: pre;
  font-size: 14px;
  color: #9a9a9a;
  position: absolute;
  top: 192px;
  .record__link {
    color: #2878ff;
  }
  .under {
    text-decoration: underline;
  }
}
.import-top {
  margin-bottom: 20px;
  margin-top: 20px;
}
.upload-box {
  position: relative;
}
.erp-form__title.req::after {
  content: '*';
  color: #f04838;
  margin-left: 4px;
}
.erp-form__title.no-leftborder {
  border-left: none;
  padding-left: 0;
}
.record {
  font-weight: 600;
  font-size: 16px;
  color: #303133;
  margin: 10px;
}
</style>
