<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">{{ showDialogTitle }}</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" @click="submit">保存</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <el-form ref="form" label-position="top" :show-message="true" :rules="rules" :model="formData">
      <div class="mali-edit__form">
        <el-row :gutter="16">
          <el-col :span="12">
            <el-form-item label="上游业务合同号" prop="contractNo">
              <el-input
                :maxlength="60"
                v-model="formData.contractNo"
                clearable
                @blur="changeContractNo($event)"
                placeholder="请输入上游业务合同号"
                class="mali-full__inputcom"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约日期" prop="signDate">
              <el-date-picker v-model="formData.signDate" type="date" placeholder="请选择签约日期" prefix-icon=" " style="width: 100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合同金额(元)" prop="amount">
              <el-input
                :maxlength="11"
                v-model="formData.amount"
                clearable
                @blur="changeDecimal($event, 'amount', 100000000, 8)"
                placeholder="请输入合同金额(元)"
                class="mali-full__inputcom"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生效日期" prop="startDate">
              <el-date-picker v-model="formData.startDate" type="date" placeholder="请选择生效日期" prefix-icon=" " style="width: 100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到期日期" prop="endDate">
              <el-date-picker v-model="formData.endDate" type="date" placeholder="请选择到期日期" prefix-icon=" " style="width: 100%"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="合同备注" prop="remark">
              <el-input
                v-model="formData.remark"
                placeholder="请输入合同备注"
                :maxlength="300"
                class="mali-full__input11"
                type="textarea"
                :autosize="{ minRows: 3 }"
                resize="none"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <el-col :span="24">
        <!-- 新增产品信息 -->
        <div v-if="editData.category === 2 || editData.category === 3">
          <div class="erp-form__title f16 mb-16">产品信息</div>
          <el-table
            :data="productList"
            border
            class="egrid erp-table__list erp-view__table erp-form-table"
            :show-summary="productList && productList.length > 1"
            :summary-method="getSummary"
          >
            <el-table-column label="大类" prop="category" align="center" show-overflow-tooltip minWidth="60">
              <template #default="scope">
                <div>{{ filterName(scope.row.categoryDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="country" align="center" show-overflow-tooltip minWidth="60">
              <template #default="scope">
                <div>{{ filterName(scope.row.countryDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="厂号" prop="plantNo" align="center" show-overflow-tooltip minWidth="120">
              <template #default="scope">
                <div>{{ filterName(scope.row.plantNo) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="中文品名" prop="nameCn" align="center" show-overflow-tooltip minWidth="200">
              <template #default="scope">
                <div>{{ filterName(scope.row.nameCn) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="质押单价(元/kg)" prop="pledgePrice" align="center" show-overflow-tooltip minWidth="120">
              <template #default="scope">
                <number-input v-model="scope.row.pledgePrice" :max="9999.99999" :min="0" :digits="5" clearable></number-input>
              </template>
            </el-table-column>
            <el-table-column label="件数" prop="packageCount" align="center" show-overflow-tooltip minWidth="60">
              <template #default="scope">
                <div>{{ filterName(scope.row.packageCountDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="合同重量(kg)" prop="contractWeight" align="center" show-overflow-tooltip minWidth="80">
              <template #default="scope">
                <div>{{ filterName(scope.row.contractWeightDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="合同货值" prop="contractPrice" align="center" show-overflow-tooltip minWidth="80">
              <template #default="scope">
                <div>{{ _thousandBitSeparator(_formatFloat(scope.row.contractWeight * scope.row.pledgePrice, 2)) }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
      <el-col :span="24">
        <!-- 新增资金方付上游 -->
        <div class="funding-table" v-if="editData.category === 2 || editData.category === 3">
          <div class="erp-form__title f16 funding">资金方付上游</div>
          <el-table :data="fundingList" border class="egrid erp-table__list erp-view__table mb-30 erp-form-table">
            <el-table-column label="付款时间" prop="payTime" align="center" show-overflow-tooltip minWidth="130">
              <template #default="scope">
                <el-date-picker v-model="scope.row.payTime" placeholder="请选择付款时间" clearable prefix-icon=" "></el-date-picker>
              </template>
            </el-table-column>
            <el-table-column label="付款金额" prop="payAmount" align="center" show-overflow-tooltip minWidth="180">
              <template #default="scope">
                <number-input v-model="scope.row.payAmount" placeholder="请输入付款金额" clearable :max="99999999.99" :min="0" :digits="2"></number-input>
              </template>
            </el-table-column>
            <el-table-column label="凭据文件" prop="attachmentDtos" align="center" show-overflow-tooltip minWidth="120">
              <template #default="scope">
                <div class="fileUrl-box">
                  <tableFileContent
                    :maxImg="5"
                    :rowIndex="scope.$index"
                    :fileUrlsArray="scope.row.attachmentDtos"
                    key-name="name"
                    key-url="url"
                    key-id="id"
                    setKeyName="name"
                    setKeyUrl="url"
                    keyUrl="url"
                    @getFileUrls="getRowFileUrls"
                  >
                  </tableFileContent>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" align="center" show-overflow-tooltip minWidth="520">
              <template #default="scope">
                <el-input v-model="scope.row.remark" placeholder="请输入备注" :maxlength="300"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>

      <div class="pd-20">
        <el-col :span="24">
          <el-form-item label="合同文件" prop="contractAttachments">
            <div class="upload-box">
              <SingleFileContent :readOnly="readOnly" :fromType="fileUploadFormType" :fileType="1" :max="3" :fileList="fileUrlsArray" @success="getFiles">
              </SingleFileContent>
            </div>
          </el-form-item>
        </el-col>
      </div>
    </el-form>

    <div class="funding-bottom"></div>
  </div>
</template>
<script>
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
import SingleFileContent from '@/components/FileContent/FileContent.vue';
import { httpGet, httpPost } from '@/api';
import NumberInput from '@/components/NumberInput/NumberInput.vue';
import { ajaxLoading, errorTip, formatTime } from '@/utils/publicMethods';
export default {
  components: {
    NumberInput,
    tableFileContent,
    SingleFileContent,
  },
  props: {
    editData: {
      type: [Object],
    },
    orderIdArray: {
      type: Array,
      default() {
        return [];
      },
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑  5选择销售合同后来到合同页面
      type: [String, Number],
      default: '',
    },
    showData: {
      // 回填表单数据
      type: [Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      productList: [],
      fundingList: [
        {
          payTime: null,
          payAmount: null,
          payAmountDesc: null,
          remark: null,
          attachmentDtos: [],
        },
      ],

      fileUrlsArray: [],
      formData: {
        // 绑定表单数据
        id: null,
        orderId: null,
        contractNo: null,
        signDate: null,
        amount: null,
        startDate: null,
        contractAttachments: [], // 合同文件
        endDate: null,
        remark: null,
        name: null,
        url: null,
        version: null,
      },
      disabled: false,
      rules: {
        // 检验规则
        contractNo: [
          {
            required: true,
            message: '请输入上游业务合同号',
            trigger: 'change',
          },
        ],
        contractAttachments: [
          {
            required: true,
            message: '请选择合同文件',
            trigger: 'change',
          },
        ],
        signDate: [
          {
            required: true,
            message: '请选择签约日期',
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    readOnly() {
      return Number(this.formType) === 3; // 新增 编辑允许修改
    },
    showDialogTitle() {
      let title = '新增合同';
      if (Number(this.formType) === 2) {
        title = '编辑合同';
      }
      if (Number(this.formType) === 5) {
        title = '编辑合同并上传合同文件';
      }
      return title;
    },
    fileUploadFormType() {
      let type = this.formType;
      if (Number(this.formType) === 5) {
        type = 2;
      }
      return type;
    },
  },
  created() {
    this.formData.orderId = this.editData && this.editData.orderId ? this.editData.orderId : null; // 新增/编辑状态都需要订单号id
    if (this.formType === 2) {
      // 如果是编辑状态
      this.formData = { ...this.editData };
      this.fileUrlsArray = [
        {
          name: this.formData.name,
          url: this.formData.url,
        },
      ];
      for (const item in this.formData) {
        if (this.formData[item] === '-') {
          // 如果编辑状态备注值为“-” 则转换成‘’
          this.formData[item] = '';
        }
      }
      // 合同号支持输入-  所以特殊处理
      if (this.editData.isContractNo === 0) {
        this.formData.contractNo = this.editData.contractNo;
      }
      this.initEditData(this.editData.id);
    }
    this.initData(this.editData.orderId);
  },
  methods: {
    async initData(id) {
      let res = await httpGet(`/malicrm/order/InsertGetProductMessageByOrderId/${id}`);
      if (res.code === 200) {
        this.productList = res.result;
        console.log('this.productList', this.productList);
      } else {
        errorTip(res.message);
      }
    },
    async initEditData(id) {
      let res = await httpGet(`/malicrm/order/queryOrderContractDetail/${id}`);
      if (res.code === 200) {
        this.fileUrlsArray = [];
        this.productList = res.result.productVoList;
        if (res.result.upstreamVo !== null) {
          this.fundingList = [res.result.upstreamVo];
        }
        this.getFiles(res.result.contractAttachments);
        res.result.contractAttachments.forEach((v) => {
          let obj = {
            name: v.name,
            url: v.url,
          };
          this.fileUrlsArray.push(obj);
        });
      } else {
        errorTip(res.message);
      }
    },
    // 获取合同文件
    getFiles(data) {
      let arr = [];
      data.forEach((v) => {
        let obj = {
          name: v.name,
          url: v.url,
        };
        arr.push(obj);
      });
      this.formData.contractAttachments = arr;
      this.$refs.form.validateField('contractAttachments');
    },
    getRowFileUrls({ data, index }) {
      console.log('data', data);
      console.log('index', index);
      let { name, url } = data[0];

      console.log('name', name);
      console.log('url', url);
      // todo
      this.fundingList[index].attachmentDtos = data;
      console.log(this.fundingList);
    },
    // 合计行
    getSummary({ data }) {
      let packageCountTotal = 0;
      data.forEach((v) => {
        packageCountTotal += v.packageCount;
      });
      let contractWeightTotal = 0;
      data.forEach((v) => {
        contractWeightTotal += v.contractWeight;
      });
      let contractPriceTotal = 0;
      data.forEach((v) => {
        contractPriceTotal += v.contractPrice;
      });
      return ['合计', '', '', '', '', packageCountTotal, contractWeightTotal, contractPriceTotal];
    },
    changeContractNo(e) {
      let value = e.target.value;
      value = value.replace(/[^a-zA-Z\d-]/g, ''); // 清除中文英文以外的字符
      this.formData.contractNo = value;
    },
    /**
     * 检查小数位数
     * @param e 时间
     * @param name 属性名
     * @param len 最大整数位数
     */
    changeDecimal(e, name, len, dot) {
      let value = e.target.value;
      // 格式化汇率格式
      value = value.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); // eslint-disable-line
      if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (value.substr(0, 1) === 0) {
          value = value.substr(1);
        }
      }
      if (value.lastIndexOf('.') === value.length - 1) {
        value = value.replace('.', '');
      }
      if (value >= len) {
        const index = value.indexOf('.');
        if (index > -1) {
          const intStr = value.substr(0, index - 1);
          const dotStr = value.substr(index);
          value = intStr + dotStr;
        } else {
          value = value.substr(0, dot); // 截取整数位数
        }
      }
      // 只能输入两个小数
      this.formData[name] = value;
      if (name === 'amount') {
        this.formData[name] = value;
      }
    },
    /**
     * 提交事件
     */
    submit() {
      console.log('this.formData', this.formData);
      console.log('🚀 ~ file: Form.vue ~ line 434 ~ this.$refs.form.validate ~ this.formData', this.formData);
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.contractAttachments && this.formData.contractAttachments.length > 0) {
            this.saveSubmitForm();
          } else {
            this.errorTip('请选择合同文件');
            this.disabled = true; // 防止保存按钮重复点击
            setTimeout(() => {
              this.disabled = false;
            }, 3000);
          }
        }
      });
    },
    async saveSubmitForm() {
      // 保存或编辑表单
      const params = JSON.parse(JSON.stringify(this.formData));
      params.amount = params.amount === null ? params.amount : params.amount;
      // 特殊处理时间
      params.signDate = formatTime(params.signDate, 'YYYY-MM-DD');
      params.startDate = formatTime(params.startDate, 'YYYY-MM-DD');
      params.endDate = formatTime(params.endDate, 'YYYY-MM-DD');
      params.upstreamDto = this.fundingList[0];
      params.contractProductDtos = this.productList;
      let url = '/malicrm/order/saveOrderContract';
      if (this.formType === 5) {
        params.orderIdArray = this.orderIdArray;
        url = '/malicrm/order/batchSaveOrderContract';
      }
      ajaxLoading.lock();
      const res = await httpPost(url, params);
      if (res.code === 200) {
        ajaxLoading.unLock();
        this.successTip('保存成功');
        this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
      } else {
        ajaxLoading.unLock();
        this.errorTip(res.message);
      }
    },
    back() {
      // 返回操作
      if (Number(this.formType) === 5) {
        this.$emit('chooseBack');
      } else {
        this.$emit('success', false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-box {
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  padding: 16px;
}
.mb-16 {
  margin: 10px 0 12px 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.funding {
  margin: 30px 200px 12px 20px;
}

.funding-bottom {
  width: 200px;
  height: 200px;
}
.pd-20 {
  padding: 20px 20px 6px 20px;
}
</style>
