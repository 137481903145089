
import { defineComponent, getCurrentInstance, nextTick, reactive, ref, toRaw } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import ContractForm from './components/Form.vue';
import ExportContact from './components/ExportContact.vue';
import { erpConfirm, errorMessage, exportFile, loadPage } from '@/utils/publicMethods';
import FormDetail from './components/FormDetail.vue';
import ChooseContract from './components/ChooseContract.vue';
import useTimer from '@/hooks/useTimer';
export default defineComponent({
  name: 'ContractOrderList',
  components: { ErpList, ContractForm, FormDetail, ChooseContract, ExportContact },
  props: {},
  setup() {
    const { startTimer, timeStatus } = useTimer();
    const Instance = getCurrentInstance() as any;
    const { columnList, tableRef } = useListData(Instance);
    const { visible, showVisible, closeVisible } = useVisible();
    const data = reactive({
      editData: null,
      orderIdArray: [],
    });
    const formType = ref(1);
    const formRef = ref();
    const saveTagInput = ref();
    const formData: any = reactive({
      businessContractNos: [],
      inputVisible: false,
      inputValue: '',
    });
    const filterDialog = ref(false);
    const rules = reactive({
      businessContractNos: [{ required: true, message: '请输入销售合同号', trigger: 'blur' }],
    });
    // methods
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    // 返回
    const cancel = (status = false) => {
      closeVisible();
      if (status) {
        refreshTable();
      }
    };
    // 上传记录
    const toUploadRecord = () => {
      loadPage('UploadLog', {});
    };
    // 导出
    const exportFileByHead = () => {
      // 30秒内只能点击一次方法，
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/order/exportContract',
            data: tableRef.value.getQueryParams(), // todo传入数据还没修改
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };
    // 导入合同
    const exportContact = () => {
      // todo-wy
      formType.value = 6;
      showVisible();
    };
    // 查看详情
    const rowClick = (row: any, column: { label: any }) => {
      const { label } = column;
      if (label === '销售合同号') {
        if (row.category === 1) {
          //期货详情
          loadPage('OrderDetail', {
            orderId: row.orderId,
            version: row.version,
            name: 'ContractOrderList',
          });
        } else {
          //现货详情
          loadPage('OrderSpotDetail', {
            orderId: row.orderId,
            version: row.version,
            name: 'ContractOrderList',
          });
        }
      } else if (row.contractNo && row.contractNo !== '' && label !== '操作') {
        data.editData = toRaw(row);
        formType.value = 3;
        showVisible();
      }
    };
    // 新增表单
    const add = (row) => {
      data.editData = toRaw(row);
      formType.value = 1;
      showVisible();
    };
    // 编辑表单
    const edit = () => {
      formType.value = 2;
      showVisible();
    };
    const handleInputConfirm = () => {
      const inputValue = formData.inputValue;
      if (inputValue && !formData.businessContractNos.includes(inputValue)) {
        formData.businessContractNos.push(inputValue);
      }
      formData.inputVisible = false;
      formData.inputValue = '';
    };
    const handleClose = (tag) => {
      formData.businessContractNos.splice(formData.businessContractNos.indexOf(tag), 1);
    };
    const showInput = () => {
      formData.inputVisible = true;
      nextTick(() => {
        saveTagInput.value.$refs.input.focus();
      });
    };
    const cancelUploadDialog = () => {
      filterDialog.value = false;
      closeVisible();
    };
    const sureUploadDialog = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          filterDialog.value = false;
          formType.value = 4;
          showVisible();
        }
      });
    };
    const chooseContractCallback = (row) => {
      data.orderIdArray = row;
      closeVisible();
      formType.value = 1;
      setTimeout(() => {
        formType.value = 5;
        showVisible();
      }, 500);
    };
    const chooseContractNextBack = () => {
      filterDialog.value = false;
      formType.value = 4;
      showVisible();
    };
    return {
      exportContact,
      exportFileByHead,
      chooseContractNextBack,
      saveTagInput,
      chooseContractCallback,
      showInput,
      handleInputConfirm,
      cancelUploadDialog,
      handleClose,
      sureUploadDialog,
      formRef,
      rules,
      filterDialog,
      formData,
      data,
      add,
      edit,
      toUploadRecord,
      cancel,
      closeVisible,
      columnList,
      tableRef,
      refreshTable,
      visible,
      showVisible,
      rowClick,
      formType,
    };
  },
});
