<template>
  <div>
    <div class="erp-form__header header-bar__sticky">
      <div class="erp-form__title">查看合同</div>
      <div class="erp-form-bar">
        <div class="erp-primary__btn" v-if="hasPermission('erp:order:saveOrderContract')" @click="submit">编辑</div>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="order-base__warp">
      <el-row :gutter="40" type="flex" class="flex-warp pd-30">
        <el-col :span="12">
          <erp-view label="上游业务合同号" :value="editData.contractNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="签约日期" :value="editData.signDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="合同金额(元)" :value="editData.amount"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="生效日期" :value="editData.startDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="到期日期" :value="editData.endDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="合同文件" :value="editData.name">
            <!-- <div class="card-preview">
              <span>{{ editData.name }}</span>
              <img src="../../../../../assets/images/preview__zoom.png" alt="" @click="$showFiles(editData.name, editData.url)" />
              <img src="../../../../../assets/images/preview_download.png" alt="" @click="$showFiles(editData.name, editData.url, false)" />
            </div> -->
            <tableFileContent
              :readOnly="true"
              :fileUrlsArray="detailData.contractAttachments"
              key-name="name"
              key-url="url"
              key-id="attachmentId"
            ></tableFileContent>
          </erp-view>
        </el-col>
        <el-col :span="24">
          <erp-view label="合同备注" :value="editData.remark"></erp-view>
        </el-col>
        <template v-if="editData.category === 2 || editData.category === 3">
          <div class="erp-form__title f16 mb-16">产品信息</div>
          <el-table
            :data="detailData.productVoList"
            border
            class="egrid erp-table__list erp-view__table"
            :show-summary="detailData.productVoList && detailData.productVoList.length > 1"
            :summary-method="getSummary"
          >
            <el-table-column label="大类" prop="category" align="center" show-overflow-tooltip minWidth="60">
              <template #default="scope">
                <div>{{ filterName(scope.row.categoryDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="country" align="center" show-overflow-tooltip minWidth="96">
              <template #default="scope">
                <div>{{ filterName(scope.row.countryDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="厂号" prop="plantNo" align="center" show-overflow-tooltip minWidth="166">
              <template #default="scope">
                <div>{{ filterName(scope.row.plantNo) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="中文品名" prop="nameCn" align="center" show-overflow-tooltip minWidth="204">
              <template #default="scope">
                <div>{{ filterName(scope.row.nameCn) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="质押单价(元/kg)" prop="pledgePrice" align="center" show-overflow-tooltip minWidth="110">
              <template #default="scope">
                <div>{{ filterName(scope.row.pledgePrice) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="件数" prop="packageCount" align="center" show-overflow-tooltip minWidth="80">
              <template #default="scope">
                <div>{{ filterName(scope.row.packageCountDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="合同重量(kg)" prop="contractWeight" align="center" show-overflow-tooltip minWidth="130">
              <template #default="scope">
                <div>{{ filterName(scope.row.contractWeightDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="合同货值" prop="contractPrice" align="center" show-overflow-tooltip minWidth="130">
              <template #default="scope">
                <div>{{ _thousandBitSeparator(_formatFloat(scope.row.contractWeight * scope.row.pledgePrice, 2)) }}</div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 新增资金方付上游 -->
          <div class="erp-form__title f16 funding">资金方付上游</div>
          <el-table :data="detailData.upstreamVo" border class="egrid erp-table__list erp-view__table">
            <el-table-column label="付款时间" prop="payTime" align="center" show-overflow-tooltip minWidth="110">
              <template #default="scope">
                <div>{{ filterName(scope.row.payTime) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="付款金额" prop="payAmount" align="center" show-overflow-tooltip minWidth="160">
              <template #default="scope">
                <div>{{ filterName(scope.row.payAmountDesc) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="凭据文件" prop="attachmentDtos" align="center" show-overflow-tooltip minWidth="100">
              <!-- <template #default="scope">
                <template v-if="scope.row.attachmentDtos && scope.row.attachmentDtos.length > 0">
                  <div @click="checkCLick(scope.row)">查看</div>
                </template>
                <template v-else>-</template> -->
              <template #default="scope">
                <tableFileContent :readOnly="true" :fileUrlsArray="scope.row.attachmentDtos" key-name="name" key-url="url" key-id="id"> </tableFileContent>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" align="center" show-overflow-tooltip minWidth="320">
              <template #default="scope">
                <div>{{ filterName(scope.row.remark) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="上传人" prop="uploadPeople" align="center" show-overflow-tooltip minWidth="110">
              <template #default="scope">
                <div>{{ filterName(scope.row.uploadPeople) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="上传时间" prop="uploadTime" align="center" show-overflow-tooltip minWidth="164">
              <template #default="scope">
                <div>{{ filterName(scope.row.uploadTime) }}</div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-row>
    </div>
  </div>
</template>
<script>
import { errorTip, showFiles } from '@/utils/publicMethods';
import { httpGet } from '@/api';
import tableFileContent from '@/components/TableFileContent/TableFileContent.vue';
export default {
  components: { tableFileContent },
  props: {
    editData: {
      type: [Object],
    },
    formType: {
      // 表单操作类型 1 新增 2 编辑
      type: [String, Number],
      default: '',
    },
  },
  emit: ['edit', 'cancel'],
  data() {
    return {
      formData: {
        // 绑定表单数据
        id: null,
        orderId: null,
        contractNo: null,
        signDate: null,
        amount: null,
        startDate: null,
        endDate: null,
        remark: null,
        name: null,
        url: null,
        version: null,
      },
      detailData: {},
    };
  },
  created() {
    this.formData.orderId = this.editData.orderId;
    if (this.formType !== 1) {
      this.formData.version = this.editData.version;
    }
    this.initData(this.editData.id);
  },

  methods: {
    /**
     * 提交事件
     */
    submit() {
      this.$emit('edit');
    },
    async initData(id) {
      let res = await httpGet(`/malicrm/order/queryOrderContractDetail/${id}`);
      if (res.code === 200) {
        this.detailData = res.result;
        if (res.result.upstreamVo && res.result.upstreamVo !== null) {
          this.detailData.upstreamVo = [res.result.upstreamVo];
        } else {
          this.detailData.upstreamVo = [];
          const obj = {
            payTime: null,
            payAmount: null,
            payAmountDesc: null,
            remark: null,
            uploadPeople: null,
            uploadTime: null,
            attachmentDtos: [],
          };
          this.detailData.upstreamVo.push(obj);
        }
        console.log('this.detailData', this.detailData);
      } else {
        errorTip(res.message);
      }
    },
    getSummary({ columns, data }) {
      let packageCountTotal = 0;
      data.forEach((v) => {
        packageCountTotal += Number(v.packageCount);
        // packageCountTotal = this._thousandBitSeparator(packageCountTotal);
      });
      let contractWeightTotal = 0;
      data.forEach((v) => {
        contractWeightTotal += Number(v.contractWeight);
        // contractWeightTotal = this._thousandBitSeparator(contractWeightTotal);
      });
      let contractPriceTotal = 0;
      data.forEach((v) => {
        contractPriceTotal += Number(v.contractPrice);
        // contractPriceTotal = this._thousandBitSeparator(contractPriceTotal);
      });
      return ['合计', '', '', '', '', packageCountTotal, contractWeightTotal, contractPriceTotal];
    },
    // 资金方上游查看凭据文件
    checkCLick(row) {
      console.log(row);
      let { name, url } = row.attachmentDtos;
      console.log('name', name);
      console.log('url', url);
      showFiles(name, url);
    },
    back() {
      // 返回操作
      this.$emit('cancel', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.crosshair {
  cursor: pointer;
}
.order-base__warp {
  margin-left: 20px;
  margin-right: 20px;
}
.mb-16 {
  margin: 16px 0 16px 20px;
}
.funding {
  margin: 16px 0 16px 20px;
}
</style>
